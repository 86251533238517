import styles from './BreadCrumbs.module.css'
import clsx from 'clsx'
import { BasicStyledComponent, DSLabeledLink } from 'shared-definitions/types'
import Linked from 'shared-components/service/Linked'
import ArrowRightShadeSmall from 'shared-svg/ArrowRightShadeSmall.svg'
import React from 'react'
import { AppEventsContext } from '../contexts/AppEventsContext'

interface BreadCrumbsProps extends BasicStyledComponent {
  list: readonly DSLabeledLink[]
  place?: 'top' | 'bottom'
}

const BreadCrumbs: React.VFC<BreadCrumbsProps> = ({ className, list, place = 'top' }) => {
  const { appEvent } = AppEventsContext.useContainer()

  if (!list.length) {
    return null
  }
  return (
    <div className={clsx(styles.container, className)}>
      {list.map((item, index) => (
        <React.Fragment key={index}>
          {index ? <ArrowRightShadeSmall className={styles.arrow} /> : null}
          <Linked
            {...item.pLink}
            className={styles.link}
            onClick={() => {
              appEvent({
                event: 'page-tag-clicked',
                type: place === 'top' ? 'top-category' : 'bottom-category',
                label: item.label,
                url: item.pLink.href,
              })
            }}
          >
            {item.label}
          </Linked>
        </React.Fragment>
      ))}
    </div>
  )
}

export default BreadCrumbs
