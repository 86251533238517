import { getPostPageProps } from 'props-getters/post'
import { ExtractGetterProps } from 'shared-definitions/util-types'
import PageContainer from 'shared-components/service/PageContainer'
import { logWarning } from 'shared-code/log'
import { GetStaticPaths } from 'next'
import { DSTplPage } from 'types.aa.ds'
import dynamic from 'next/dynamic'
import TextTemplate from 'templates/TextTemplate'
import PostTemplate from 'templates/PostTemplate'
import EventTemplate from 'templates/EventTemplate'
import Layout from 'components/Layout'
import Logo from 'shared-svg/LogoAA.svg'

const BestListTemplate = dynamic(() => import('templates/BestListTemplate'))
const ReviewTemplate = dynamic(() => import('templates/ReviewTemplate'))
const AboutTemplate = dynamic(() => import('shared-components/templates/AboutTemplate'))
const RootCategoryTemplateAA = dynamic(() => import('templates/RootCategoryTemplateAA'))
const TopicTemplateAA = dynamic(() => import('templates/TopicTemplateAA'))
const CategoryTemplate = dynamic(() => import('templates/CategoryTemplate'))
const DealsCategoryTemplateAA = dynamic(() => import('templates/DealsCategoryTemplateAA'))
const ContactsTemplate = dynamic(() => import('templates/ContactsTemplate'))
const SidedCategoryTemplate = dynamic(() => import('templates/SidedCategoryTemplate'))

function getPageTpl(page: DSTplPage): React.ReactElement | null {
  switch (page.resource) {
    case 'about-page':
      return <AboutTemplate page={page} logo={Logo} />
    case 'category-page':
      return <CategoryTemplate page={page} />
    case 'deals-category-page':
      return <DealsCategoryTemplateAA page={page} />
    case 'sided-category-page':
      return <SidedCategoryTemplate page={page} />
    case 'contact-page':
      return <ContactsTemplate page={page} />
    case 'text-page':
      return <TextTemplate page={page} />
    case 'post-page':
      return <PostTemplate page={page} />
    case 'event-page':
      return <EventTemplate page={page} />
    case 'review-page':
      return <ReviewTemplate page={page} />
    case 'best-list-page':
      return <BestListTemplate page={page} />
    case 'root-category-page':
      return <RootCategoryTemplateAA page={page} />
    case 'topic-page':
      return <TopicTemplateAA page={page} />
    default:
      logWarning('Unknown tpl page', page)
      return null
  }
}

const TplPage: React.VFC<ExtractGetterProps<typeof getPostPageProps>> = ({
  preview,
  page,
  common,
  ts,
}) => (
  <PageContainer
    layout={Layout}
    props={{ tooltips: 'tooltips' in page ? page.tooltips : {}, page, common, ts, preview }}
  >
    {getPageTpl(page)}
  </PageContainer>
)

export const getStaticPaths: GetStaticPaths = () =>
  Promise.resolve({
    paths: [],
    fallback: 'blocking',
  })

export const getStaticProps = getPostPageProps()

export default TplPage
